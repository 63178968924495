import(/* webpackMode: "eager" */ "/vercel/path0/components/blobfold.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/portfolio.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@splinetool+react-spline@4.0.0_@splinetool+runtime@1.9.28_next@15.0.1_react-dom@19.0.0-rc-69d_hou4z2hqgg3ywv4giouods65hq/node_modules/@splinetool/react-spline/dist/react-spline.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-69d4b800-20241021_react@19.0.0-rc-69d4b800-20241021__react@19.0.0-rc-69d4b800-20241021/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-69d4b800-20241021_react@19.0.0-rc-69d4b800-20241021__react@19.0.0-rc-69d4b800-20241021/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-69d4b800-20241021_react@19.0.0-rc-69d4b800-20241021__react@19.0.0-rc-69d4b800-20241021/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-69d4b800-20241021_react@19.0.0-rc-69d4b800-20241021__react@19.0.0-rc-69d4b800-20241021/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
