'use client';

import Image from "next/image";
import Link from "next/link";
import { useState } from "react";

function Works(props: { workNum: any; data: any[]; load: unknown; }) {
  const [workNum, setworkNum] = useState(props.workNum);

  function incWork(){
    setworkNum((prevWork: number) => prevWork + 6)
  }

  function LoadMore({isLoadMore} : {isLoadMore: any}){
    if (isLoadMore){ 
      return props.data.length <= workNum ? null : (<button onClick={incWork} >Load More</button>) ;
    }
    return <button><Link href={'/portfolio'}>View More</Link></button>
  }

  return(
		<>
			{/* Skills */}
			<section className="sm:min-h-screen">
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-6">
					{ props.data.slice(0, workNum).map (work =>(
							<div key={work.id} className={`bg-gray-500 hover:bg-gray-900 rounded-md hover:backdrop-blur-2xl bg-opacity-20 hover:bg-opacity-30`}>
                <div>
                  <Link href={work.link} target="_blank">
                  <Image src={work.screenshot} width={1000} height={350} alt={work.name} className={'rounded-t-md'} />
                  <p className={`px-3 pt-3 bottom-1 left-0`}>{work.name}</p>
                  <p className={`px-3 pb-3 bottom-1 left-0 align-middle`}><i className="ri-external-link-line ri-sm"/> {work.link}</p>
                  </Link>
                </div>          
							</div>
						))
					}
				</div>
        <LoadMore isLoadMore={props.load} />
			</section>
		</>
	)
}

export default Works;